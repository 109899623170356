import React, { useContext } from 'react';
import { BgImage } from 'gbimage-bridge';

import {
	Button,
	Typography,
	Grid,
	makeStyles,
	useMediaQuery,
} from '@material-ui/core';

import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	ctaContainer: {
		padding: '4rem 0',
		[theme.breakpoints.down('xs')]: {
			padding: '4rem 0 0 0',
		},
	},
	button: {
		background: 'white',
		color: '#002D5C',
		padding: '6px 32px',
		opacity: 1,
		fontSize: '1.1rem',
		fontWeight: 500,
		zIndex: 1000,
		transition: 'all 0.35s ease !important',
		'&:hover': {
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0 2rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			margin: '1rem 0 3rem 0',
		},
	},
}));

export const CtaCard = ({ background, header, subheader, ctaText }) => {
	const classes = useStyles();

	const { handleModalClick } = useContext(FormModalContext);

	const md = useMediaQuery('(max-width: 960px)');
	return (
		<div className={classes.ctaContainer}>
			<BgImage
				style={{
					borderRadius: '20px',
					overflow: 'hidden',
					// backgroundSize: 'cover',
					padding: md ? '2rem 0 0 0' : '4rem 0',
				}}
				image={background.asset?.gatsbyImageData}>
				<Grid
					container
					direction='row'
					justifyContent='space-evenly'
					alignItems='center'
					style={{ height: '100%' }}>
					<Grid
						item
						container
						direction='column'
						xs={10}
						md={8}
						justifyContent='space-evenly'
						alignItems={md ? 'center' : 'flex-start'}
						style={{ height: '100%' }}>
						<Typography
							variant='h2'
							style={{ color: '#FFF', marginBottom: '1rem' }}>
							{header}
						</Typography>
						<Typography variant='body1' style={{ color: '#FFF' }}>
							{subheader}
						</Typography>
					</Grid>
					<Grid
						item
						container
						alignItems='center'
						justifyContent='center'
						xs={12}
						md={3}
						style={{ height: '100%' }}>
						<Button
							variant='contained'
							size='large'
							style={{ marginTop: md ? '2rem' : '1rem' }}
							onClick={(e) => handleModalClick()}
							className={classes.button}>
							{ctaText}
						</Button>
					</Grid>
				</Grid>
			</BgImage>
		</div>
	);
};

import React, { useState, useRef, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../../components/General/Hero';
import { WaveUpSVG } from '../../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../../components/SEO';
import { FAQ } from '../../components/General/FAQ';
import { CtaCard } from '../../components/FinancialServices/FAQ/CtaCard';
import FormModalContext from '../../context/FormModalContext';
import FaqSearchContext from '../../context/FaqSearchContext';

// import { Form } from '../../components/ContactForm/Form';

const Form = loadable(() => import('../../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	faqSection: {
		marginTop: '-12em',
		marginBottom: '15rem',
		[theme.breakpoints.down('lg')]: {
			marginTop: '-14em',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: '-10em',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-8em',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-4em',
			marginBottom: '12rem',
		},
	},
}));

const PaymentsFAQ = ({ data, location }) => {
	const classes = useStyles();
	const faqData = data.fs.edges[0].node.faq;
	const [expanded, setExpanded] = useState(0);
	const faqRef = useRef(null);

	const { formModalOpen } = useContext(FormModalContext);
	const { setDefault, filteredFaq } = useContext(FaqSearchContext);

	const {
		faqPageHero,
		metaDescription,
		metaTitle,
		ctaBackground,
		ctaHeader,
		ctaSubheader,
		ctaText,
		faq,
		faqDisclaimer,
	} = faqData;

	useEffect(() => {
		setDefault(faq);
	}, []);
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />

			<Hero hero={faqPageHero} faq={faq} faqRef={faqRef} />
			<Container className={classes.faqSection}>
				{!!filteredFaq.length ? (
					filteredFaq.map((faq, index) => (
						<FAQ
							key={index}
							faq={faq}
							index={index}
							expanded={expanded}
							setExpanded={setExpanded}
							faqRef={faqRef}
						/>
					))
				) : (
					<Typography variant='h4' color='primary'>
						No Match
					</Typography>
				)}
				<Typography variant='body1' style={{ lineHeight: '28px' }}>
					{faqDisclaimer}
				</Typography>
				<CtaCard
					background={ctaBackground}
					header={ctaHeader}
					subheader={ctaSubheader}
					ctaText={ctaText}
				/>
				{formModalOpen ? (
					<Form
           formId='3646' 
           privacy 
           noForm 
           location={location}
           pardotUrl='https://go.pestpac.com/l/1042281/2023-11-10/65smmn'
          />
				) : null}
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query FSFAQPageQuery {
		fs: allSanityFinancialServices {
			edges {
				node {
					faq: fsFaqPage {
						metaTitle
						metaDescription
						faqPageHero {
							backgroundImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							_rawContent
						}
						faq {
							question
							_rawAnswer
							answer {
								children {
									text
								}
							}
						}
						faqDisclaimer
						ctaHeader
						ctaSubheader
						ctaText
						ctaBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
				}
			}
		}
	}
`;

export default PaymentsFAQ;
